import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { Elements } from '@stripe/react-stripe-js';
import { fetchAuthSession, getCurrentUser, signOut } from 'aws-amplify/auth';
import { ConnectedRouter } from 'connected-react-router';
import _ from 'lodash';
import Store from 'store';

import { configureAmplify, LAUNCH_DARKLEY_KEY } from '@pumpkincare/config';
import {
  BannersProvider,
  captureException,
  IDENTITY_LOCAL_STORAGE_KEY,
  matchScreenSizes,
  setIsLoggedIn,
} from '@pumpkincare/shared';
import { GlobalStylesInject } from '@pumpkincare/shared/ui';
import { getUserQueryFn, USER_SELF_QUERY } from '@pumpkincare/user';

import { setUserSelf } from '../user';
import { setMediaMatches } from './state/app-ducks';
import { createMemberCenterStore, history } from './state/redux-store';
import App from './view/app';
import { configureApp, initLaunchDarkly, initStripe } from './config';

export default function AppController() {
  const store = createMemberCenterStore();
  const dispatch = store.dispatch;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        onError: captureException,
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
  });

  const identityId = Store.get(IDENTITY_LOCAL_STORAGE_KEY);
  const ldUser = {
    ...(identityId ? { key: identityId } : { anonymous: true }),
    country: window.Cypress ? 'e2e' : '',
  };
  const { withLaunchDarkly, ldClient } = initLaunchDarkly(
    LAUNCH_DARKLEY_KEY,
    ldUser
  );
  const EnhancedApp = withLaunchDarkly(App);

  bootstrap();
  authenticateUser();
  addListeners();
  render();

  function addListeners() {
    //TODO: dont dispatch on every resize, only if breakpoints change
    window.addEventListener(
      'resize',
      _.debounce(() => {
        dispatch(setMediaMatches(matchScreenSizes()));
      }, 100)
    );
  }

  function bootstrap() {
    ldClient.identify(ldUser);

    dispatch(setMediaMatches(matchScreenSizes()));

    configureApp();
  }

  function authenticateUser() {
    configureAmplify();

    getCurrentUser()
      .then(() => {
        return fetchAuthSession();
      })
      .then(() => {
        setIsLoggedIn(true);

        queryClient.prefetchQuery([USER_SELF_QUERY], () =>
          getUserQueryFn(data => {
            Sentry.setUser({ id: data.users.id });
            dispatch(setUserSelf(data));
          })
        );
      })
      .catch(() => {
        setIsLoggedIn(false);
        signOut();
      });
  }

  function render() {
    const container = document.getElementById('root');
    const root = createRoot(container);

    root.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Elements
            stripe={initStripe()}
            options={{
              fonts: [
                {
                  // load custom font to Stripe iFrame
                  cssSrc: 'https://fonts.googleapis.com/css?family=Nunito+Sans',
                },
              ],
            }}
          >
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />

              <BannersProvider>
                <GlobalStylesInject />
                <EnhancedApp />
              </BannersProvider>
            </QueryClientProvider>
          </Elements>
        </ConnectedRouter>
      </Provider>
    );
  }
}
